import React from "react";

import async from "../components/Async";

import {
  Layout,
  Sliders,
  Users,
  Settings,
  Package
} from "react-feather";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ForgotPass = async(() => import("../pages/auth/ForgotPassword"));
const ResetPass = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const SetAdminPassword = async(() =>import("../pages/auth/SetAdminPassword"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));

// Pages components
// const Blank = async(() => import("../pages/pages/Blank"));
const UserList = async(() => import("../pages/pages/Users"));
const Profile = async(() => import("../pages/pages/Profile"));
const Account = async(() => import("../components/Settings/AccountInfo"));
const Security = async(() => import("../components/Settings/Security"));
const Accounts = async(() => import('../pages/pages/Accounts'));
const IpDuplication = async(() => import('../pages/pages/IpDuplication'));
const IpDuplicationDetail = async(() => import('../pages/pages/IpDuplicationDetail'));
const Contracts = async(() => import('../pages/pages/Contracts'));
const Bros = async(() => import('../pages/pages/Bros'));

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Main",
  icon: <Sliders />,
  containsHome: true,
  component: Default,
  children: null
};

const profileRoutes = {
  id: "Profile",
  icon: <Layout/>,
  path: "/pages/profile/:id",
  name: "Profile",
  component: Profile
};

const myaccountRoutes = {
  path: "/pages/settings/account",
  name: "My Account",
  component: Account,
};

const securityRoutes = {
  path: "/pages/settings/security",
  name: "Security",
  component: Security,
};

const accountRoutes = {
  path: "/pages/settings/sub-accounts",
  name: "Sub Accounts",
  component: Accounts,
};

const subUsersRoutes = {
  path: "/pages/users/sub-users",
  name: "Sub Users",
  component: UserList
};

const ipDuplicateRoutes = {
  path: "/pages/users/ip-duplications",
  name: "IP duplication",
  component: IpDuplication
};

const ipDuplicateDetailRoutes = {
  id: "Detail",
  icon: <Layout/>,
  path: "/pages/users/ip-duplications/:ip",
  name: "Ip Duplication Detail",
  component: IpDuplicationDetail
};

const contractsRoutes = {
  id: "Smart Contracts",
  icon: <Package/>,
  path: "/pages/contracts",
  name: "Smart Contracts",
  component: Contracts
};

const brosRoutes = {
  id: "Bros",
  icon: <Package/>,
  path: "/pages/bros",
  name: "Bros",
  component: Bros
};

const usersRoutes = {
  id: "Users",
  icon: <Users />,
  path: "/pages/users",
  name: "Users",
  children: [ subUsersRoutes, ipDuplicateRoutes ]
};

const settingsRoutes = {
  id: "Settings",
  icon: <Settings/>,
  path: "/pages/settings",
  name: "Settings",
  children: [ myaccountRoutes, securityRoutes, accountRoutes ]
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      component: ForgotPass
    },
    {
      path: "/auth/reset-password/:token",
      name: "Reset Password",
      component: ResetPass
    },
    {
      path: "/admin/set-password/:token",
      name: "Set Admin Password",
      component: SetAdminPassword
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

export const dashboard = [
  dashboardsRoutes,
  usersRoutes,
  profileRoutes,
  settingsRoutes,
  myaccountRoutes,
  accountRoutes,
  subUsersRoutes,
  ipDuplicateRoutes,
  ipDuplicateDetailRoutes,
  contractsRoutes,
  brosRoutes
];

export const auth = [authRoutes];

export default [
  dashboardsRoutes,
  usersRoutes,
  contractsRoutes,
  brosRoutes,
  settingsRoutes
];
