import axios from 'axios';
import * as actionTypes from '../constants';
import errorHandler from '../../helpers/exceptions';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

const axiosConfig = token => ({
  headers: {
    ...(token && {Authorization: `Bearer ${token}`})
  }
});

const authActions = {

  getDeviceInfo: function(data) {
    return dispatch => {
      dispatch(this.getDeviceInfoStart());

      return dispatch(this.getDeviceInfoSuccess(data));
    };
  },

  getDeviceInfoStart: function() {
    return {
      type: actionTypes.USER_DEVICE_INFO
    };
  },

  getDeviceInfoSuccess: function(data) {
    return {
      type: actionTypes.USER_DEVICE_INFO_SUCCESS,
      data
    };
  },
  // login
  login(data) {
    return (dispatch) => {
      dispatch(this.loginStart());

      return instance
        .post('/login', data)
        .then((response) => {
          if (response.data.message && response.data.message === 'TFA Enabled') {
            // TFA enabled
            dispatch(
              this.tfaEnabled({
                token: response.data.token
              })
            );
            return Promise.resolve(true);
          } else {
            localStorage.setItem('tozex_admin_token', response.data.token);
            dispatch(this.loginSuccess(response.data));
            return Promise.resolve(null);
          }
        })
        .catch((error) => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.loginError(message));
          return Promise.reject(message);
        });
    };
  },

  loginStart() {
    return {
      type: actionTypes.USER_LOGIN
    };
  },

  loginSuccess(data) {
    return {
      type: actionTypes.USER_LOGIN_SUCCESS,
      data
    };
  },

  loginError(errorMsg) {
    return {
      type: actionTypes.USER_LOGIN_ERROR,
      errorMsg
    };
  },

  // fetch current user
  fetchCurrentUser: function(token) {
    return dispatch => {
      dispatch(this.fetchCurrentUserStart());

      return instance
        .get('account/profile', axiosConfig(token))
        .then(response => {
          dispatch(this.fetchCurrentUserSuccess(response.data));
          return Promise.resolve();
        })
        .catch(error => {
          errorHandler(error);
          localStorage.removeItem('tozex_admin_token');
          const message = error.response.data.error.msg || error.message;
          dispatch(this.fetchCurrentUserError(message));
          return Promise.reject({ message });
        });
    };
  },

  fetchCurrentUserStart: function() {
    return {
      type: actionTypes.FETCH_CURRENT_USER
    };
  },

  fetchCurrentUserSuccess: function(data) {
    return {
      type: actionTypes.FETCH_CURRENT_USER_SUCCESS,
      data
    };
  },

  fetchCurrentUserError: function(errorMsg) {
    return {
      type: actionTypes.FETCH_CURRENT_USER_ERROR,
      errorMsg
    };
  },

  // sign out
  signOut: function() {
    return dispatch => {
      dispatch(this.signOutStart());

      return instance
        .get('logout', axiosConfig(localStorage.getItem('tozex_admin_token')))
        .then(() => dispatch(this.signOutSuccess()))
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.signOutError(message));
        })
        .then(() => {
          localStorage.removeItem('tozex_admin_token');
        });
    };
  },

  signOutStart: function() {
    return {
      type: actionTypes.USER_SIGNOUT
    };
  },

  signOutSuccess: function() {
    return {
      type: actionTypes.USER_SIGNOUT_SUCCESS
    };
  },

  signOutError: function(errorMsg) {
    return {
      type: actionTypes.USER_SIGNOUT_ERROR,
      errorMsg
    };
  },


  tfaLogin: function({ token, code }) {
    return dispatch => {
      dispatch(this.tfaLoginStart());

      return instance
        .post('/login/tfa', { code }, axiosConfig(token))
        .then(response => {
          localStorage.setItem('tozex_admin_token', response.data.token);
          dispatch(this.tfaLoginSuccess(response.data));
          return Promise.resolve(response.data);
        })
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.tfaLoginError(message));
          return Promise.reject({ message });
        });
    };
  },

  tfaLoginStart: function() {
    return {
      type: actionTypes.USER_TFA_LOGIN
    };
  },

  tfaLoginSuccess: function(data) {
    return {
      type: actionTypes.USER_TFA_LOGIN_SUCCESS,
      data
    };
  },

  tfaLoginError: function(errorMsg) {
    return {
      type: actionTypes.USER_TFA_LOGIN_ERROR,
      errorMsg
    };
  },

  denyTfa: function(token) {
    return dispatch => {
      dispatch(this.denyTfaStart());

      return instance
        .post('/deny/tfa', {}, axiosConfig(token))
        .then(response => {
          dispatch(this.denyTfaSuccess(response.data));
          return Promise.resolve();
        })
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.denyTfaError(message));
          return Promise.reject({ message });
        });
    };
  },

  denyTfaStart: function() {
    return {
      type: actionTypes.USER_TFA_DENY
    };
  },

  denyTfaSuccess: function(data) {
    return {
      type: actionTypes.USER_TFA_DENY_SUCCESS,
      data
    };
  },

  denyTfaError: function(errorMsg) {
    return {
      type: actionTypes.USER_TFA_DENY_ERROR,
      errorMsg
    };
  },

  tfaEnabled: function(data) {
    return {
      type: actionTypes.USER_TFA_ENABLED,
      data
    };
  }
};

export default authActions;
