import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const UnauthenticatedRoute = (Layout, routes, childProps) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            !childProps.isAuthenticated ? (
              <Layout>
                <Component {...props} />
              </Layout>
            ) : (
              <Redirect to='/dashboard'/>
            )
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          !childProps.isAuthenticated ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect to='/dashboard'/>
          )
        )}
      />
    )
  );

export default UnauthenticatedRoute;
