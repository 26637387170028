import React from 'react';
import { handleError, handleWarning, handleInfo, handlePermission } from './toast';


const errorHandler = error => {
  const status = error.response ? error.response.status : 500;
  const message = error.response ? (error.response.data ? error.response.data.error.msg : error.response ) : error.message;
  if (status === 500) {
    handleError(message);
  }
  if ((status === 401) && (message === 'Token expired.')) {
    handleInfo(message);
    localStorage.removeItem('tozex_admin_token');
    window.location.href = '/';
  }
  if (status === 400) {
    handleError(message);
  }
  if ((status === 401) && (message !== 'No Authorization Header.')) {
    handleWarning(message);
  }
  if (status === 404) {
    handleInfo(message);
  }
  if (status === 403) {
    handlePermission(message);
  }
};
export default errorHandler;
