import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';

import { Provider } from 'react-redux';
import configureStore, { history } from './redux/store/index';
import socketMiddleware from './redux/middlewares/socket';
import './index.css';
import App from './App';

const store = configureStore();
socketMiddleware.setupStore(store);
socketMiddleware.setupSocket();
socketMiddleware.connect('reconnect', {});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>, document.getElementById('root'));
