import * as actionTypes from '../constants';

const initialState = {
  userId: null,
  usersCount: 0,
  usersValidCount: 0,
  totalUsersCount: 0,

  subTotalCount: 0,
  initUsers: [],
  totalUsers: [],
  totalUsersLoading: false,
  totalUsersError: null,
  contracts: {
    total: 0,
    contracts: []
  },
  smartContracts: [],
  totalSmartContracts: 0,
  bros: [],
  totalBros: 0,
  historyData: [],
  refereeData: [],
  transUsers: [],
  refereeCounter: 0,
  referrerCounter: 0,
  totalToz: 0,
  realToz: 0,
  transNumber: 0,
  currentUser: {},
  referrerUser: {},
  userToken: '',
  refreshToken: '',
  permission: '',
  dateTotalUsers: 0,
  dateUsers: 0,
  maxTotalDate: 0,
  kycUsersCount: 0,
  brosInfo: {
    preRegistered: 0,
    investAmount: 0
  },
  broData: {},
  dailySignUps: [],
  dailyInvestments: [],
  usersConfirmation: {},
  ipDuplications: [],
  totalIpDuplications: 0,
  ipUsers: [],
  ipUsersError: null,
  usersFetchError: null,
  usersFetchTotalError: null,
  usersCreateError: null,
  usersUpdateError: null,
  usersDeleteError: null,
  usersFetchSpecialError: null,
  usersUpdatePassError: null,
  usersDisable2FAError: null,
  usersResetTozError: null,
  usersSendLinkError: null,
  contractsError: null,

  contractsLoading: false,
  ipUsersLoading: false,
  usersFetchLoading: false,
  usersFetchTotalLoading: false,
  usersCreateLoading: false,
  usersUpdateLoading: false,
  usersDeleteLoading: false,
  usersFetchSpecialLoading: false,
  usersUpdatePassLoading: false,
  usersDisable2FALoading: false,
  usersResetTozLoading: false,
  usersSendLinkLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.USERS_FETCH:
      return {
        ...state,
        usersFetchLoading: true
      };

    case actionTypes.USERS_FETCH_SUCCESS:
      return {
        ...state,
        usersFetchLoading: false,
        usersFetchError: null,
        users: action.data.users,
        subTotalCount: action.data.total,
        usersValidCount: action.data.usersValidCount
      };

    case actionTypes.USERS_FETCH_ERROR:
      return {
        ...state,
        usersFetchLoading: false,
        usersFetchError: action.errorMsg
      };

    case actionTypes.USERS_FETCH_TOTAL:
      return {
        ...state,
        usersFetchTotalLoading: true
      };

    case actionTypes.USERS_FETCH_TOTAL_SUCCESS:
      return {
        ...state,
        usersFetchTotalLoading: false,
        usersFetchTotalError: null,
        totalUsersCount: action.data.totalUsersCount,
        usersCount: action.data.usersCount,
        initUsers: action.data.initUsers,
        contracts: action.data.contracts,
        refereeCounter: action.data.refereeCounter,
        referrerCounter: action.data.referrerCounter,
        totalToz: action.data.totalToz,
        realToz: action.data.realToz,
        brosInfo: action.data.bros,
        kycUsersCount: action.data.kycUsersCount,
        dailyInvestments: action.data.dailyInvestments,
        usersConfirmation: action.data.usersConfirmation
      };

    case actionTypes.USERS_FETCH_TOTAL_ERROR:
      return {
        ...state,
        usersFetchTotalLoading: false,
        usersFetchTotalError: action.errorMsg
      };

    case actionTypes.USERS_FETCH_TOTAL_DATE:
      return {
        ...state,
        usersFetchTotalDateLoading: true
      };

    case actionTypes.USERS_FETCH_TOTAL_DATE_SUCCESS:
      return {
        ...state,
        usersFetchTotalDateLoading: false,
        usersFetchTotalDateError: null,
        dateTotalUsers: action.data.dateTotalUsers,
        dateUsers: action.data.dateUsers,
        maxTotalDate: action.data.maxTotalDate
      };

    case actionTypes.USERS_FETCH_TOTAL_DATE_ERROR:
      return {
        ...state,
        usersFetchTotalDateLoading: false,
        usersFetchTotalDateError: action.errorMsg
      };

    case actionTypes.USERS_CREATE:
      return {
        ...state,
        usersCreateLoading: true
      };

    case actionTypes.USERS_CREATE_SUCCESS:
      return {
        ...state,
        usersCreateLoading: false,
        usersCreateError: null,
        users: action.data.users,
        usersValidCount: action.data.usersValidCount,
      };

    case actionTypes.USERS_CREATE_ERROR:
      return {
        ...state,
        usersCreateLoading: false,
        usersCreateError: action.errorMsg
      };

    case actionTypes.USERS_UPDATE:
      return {
        ...state,
        usersUpdateLoading: true
      };

    case actionTypes.USERS_UPDATE_SUCCESS:
      return {
        ...state,
        usersUpdateLoading: false,
        usersUpdateError: null,
        users: action.data.users,
        usersValidCount: action.data.usersValidCount,
      };

    case actionTypes.USERS_UPDATE_ERROR:
      return {
        ...state,
        usersUpdateLoading: false,
        usersUpdateError: action.errorMsg
      };

    case actionTypes.USERS_DELETE:
      return {
        ...state,
        usersDeleteLoading: true
      };

    case actionTypes.USERS_DELETE_SUCCESS:
      return {
        ...state,
        usersDeleteLoading: false,
        usersDeleteError: null,
        users: action.data.users,
        usersValidCount: action.data.usersValidCount,
      };

    case actionTypes.USERS_DELETE_ERROR:
      return {
        ...state,
        usersDeleteLoading: false,
        usersDeleteError: action.errorMsg
      };

    case actionTypes.USERS_FETCH_SPECIAL:
      return {
        ...state,
        usersFetchSpecialLoading: true
      };

    case actionTypes.USERS_FETCH_SPECIAL_SUCCESS:
      return {
        ...state,
        usersFetchSpecialLoading: false,
        usersFetchSpecialError: null,
        currentUser: action.data.currentUser,
        historyData: action.data.historyData,
        referrerUser: action.data.referrerUser,
        refereeData: action.data.refereeData,
        broData: action.data.broData
      };

    case actionTypes.USERS_FETCH_SPECIAL_ERROR:
      return {
        ...state,
        usersFetchSpecialLoading: false,
        usersFetchSpecialError: action.errorMsg
      };

    case actionTypes.USERS_UPDATE_PASSWORD:
      return {
        ...state,
        usersUpdatePassLoading: true
      };

    case actionTypes.USERS_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        usersUpdatePassLoading: false,
        usersUpdatePassError: null,
      };

    case actionTypes.USERS_UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        usersUpdatePassLoading: false,
        usersUpdatePassError: action.errorMsg
      };

    case actionTypes.USERS_SEND_LINK:
      return {
        ...state,
        usersSendLinkLoading: true
      };

    case actionTypes.USERS_SEND_LINK_SUCCESS:
      return {
        ...state,
        usersSendLinkLoading: false,
        usersSendLinkError: null,
      };

    case actionTypes.USERS_SEND_LINK_ERROR:
      return {
        ...state,
        usersSendLinkLoading: false,
        usersSendLinkError: action.errorMsg
      };

    case actionTypes.USERS_DISABLE_2FA:
      return {
        ...state,
        usersDisable2FALoading: true
      };

    case actionTypes.USERS_DISABLE_2FA_SUCCESS:
      return {
        ...state,
        usersDisable2FALoading: false,
        usersDisable2FAError: null,
      };

    case actionTypes.USERS_DISABLE_2FA_ERROR:
      return {
        ...state,
        usersDisable2FALoading: false,
        usersDisable2FAError: action.errorMsg
      };

    case actionTypes.USERS_RESET_TOZ:
      return {
        ...state,
        usersResetTozLoading: true
      };

    case actionTypes.USERS_RESET_TOZ_SUCCESS:
      return {
        ...state,
        usersResetTozLoading: false,
        usersResetTozError: null,
      };

    case actionTypes.USERS_RESET_TOZ_ERROR:
      return {
        ...state,
        usersResetTozLoading: false,
        usersResetTozError: action.errorMsg
      };

//change store
    case actionTypes.USERS_CHANGE_STORE_SUCCESS:
      return {
        ...state,
        currentUser: action.data.currentUser
      };

    case actionTypes.FETCH_TRANS_USERS_SUCCESS:
      return {
        ...state,
        transNumber: action.payload.usersData.number,
      };

    case actionTypes.USERS_FETCH_IP_DUPLICATION:
      return {
        ...state,
        usersFetchIpDuplicationLoading: true
      };

    case actionTypes.USERS_FETCH_IP_DUPLICATION_SUCCESS:
      return {
        ...state,
        usersFetchIpDuplicationLoading: false,
        usersFetchIpDuplicationError: null,
        ipDuplications: action.data.ipDuplications,
        totalIpDuplications: action.data.totalCount,
      };

    case actionTypes.USERS_FETCH_IP_DUPLICATION_ERROR:
      return {
        ...state,
        usersFetchIpDuplicationLoading: false,
        usersFetchIpDuplicationError: action.errorMsg
      };

    case actionTypes.USERS_FETCH_IP_USERS:
      return {
        ...state,
        ipUsersLoading: true
      };

    case actionTypes.USERS_FETCH_IP_USERS_SUCCESS:
      return {
        ...state,
        ipUsersLoading: false,
        ipUsersError: null,
        ipUsers: action.data.ipUsers
      };

    case actionTypes.USERS_FETCH_IP_USERS_ERROR:
      return {
        ...state,
        ipUsersLoading: false,
        ipUsersError: action.errorMsg
      };

    case actionTypes.FETCH_CONTRACTS:
      return {
        ...state,
        contractsLoading: true
      };

    case actionTypes.FETCH_CONTRACTS_SUCCESS:
      return {
        ...state,
        contractsLoading: false,
        contractsError: null,
        smartContracts: action.data.contracts,
        totalSmartContracts: action.data.total,
      };

    case actionTypes.FETCH_CONTRACTS_ERROR:
      return {
        ...state,
        contractsLoading: false,
        contractsError: action.errorMsg
      };

    case actionTypes.FETCH_BROS:
      return {
        ...state,
        brosLoading: true
      };

    case actionTypes.FETCH_BROS_SUCCESS:
      return {
        ...state,
        brosLoading: false,
        brosError: null,
        bros: action.data.bros,
        totalBros: action.data.total,
      };

    case actionTypes.FETCH_BROS_ERROR:
      return {
        ...state,
        brosLoading: false,
        brosError: action.errorMsg
      };

    case actionTypes.USERS_FETCH_ALL:
      return {
        ...state,
        totalUsersLoading: true
      };

    case actionTypes.USERS_FETCH_ALL_SUCCESS:
      return {
        ...state,
        totalUsersLoading: false,
        totalUsersError: null,
        totalUsers: action.data.users,
      };

    case actionTypes.USERS_FETCH_ALL_ERROR:
      return {
        ...state,
        totalUsersLoading: false,
        totalUsersError: action.errorMsg
      };

    case actionTypes.CREATE_BRO_INVEST:
      return {
        ...state,
        createBroLoading: true
      };

    case actionTypes.CREATE_BRO_INVEST_SUCCESS:
      return {
        ...state,
        createBroLoading: false,
        createBroError: null,
        bros: action.data.bros,
        totalBros: action.data.total
      };

    case actionTypes.CREATE_BRO_INVEST_ERROR:
      return {
        ...state,
        createBroLoading: false,
        createBroError: action.errorMsg
      };

    case actionTypes.USERS_REMOVE_TOZ:
      return {
        ...state,
        removeTozLoading: true
      };

    case actionTypes.USERS_REMOVE_TOZ_SUCCESS:
      return {
        ...state,
        removeTozLoading: false,
        removeTozError: null,
        users: action.data.users,
        subTotalCount: action.data.total,
        usersValidCount: action.data.usersValidCount
      };

    case actionTypes.USERS_REMOVE_TOZ_ERROR:
      return {
        ...state,
        removeTozLoading: false,
        removeTozError: action.errorMsg
      };

    case actionTypes.FETCH_DAILY_SIGNUPS:
      return {
        ...state,
        fetchDailySignUpsLoading: true
      };

    case actionTypes.FETCH_DAILY_SIGNUPS_SUCCESS:
      return {
        ...state,
        fetchDailySignUpsLoading: false,
        fetchDailySignUpsError: null,
        dailySignUps: action.data.dailySignUps,
      };

    case actionTypes.FETCH_DAILY_SIGNUPS_ERROR:
      return {
        ...state,
        fetchDailySignUpsLoading: false,
        fetchDailySignUpsError: action.errorMsg
      };

    default:
      return state;
  }
};
