const State = {
  toastStatus: {
    ERROR: 'danger',
    INFORMATION: 'info',
    WARNING: 'warning'
  },
  toastIcon: {
    ERROR: 'error',
    INFORMATION: 'info',
    WARNING: 'warning'
  }
};

module.exports = State;
