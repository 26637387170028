import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import authActions from '../redux/actions/auth';

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import { Power } from "react-feather";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

class Header extends Component {
  state = {
    anchorMenu: null
  };

  signOut = e => {
    e.preventDefault();

    this.props.signOut(this.props.token).then(() => {
      window.location.href = '/';
    });
  };

  render() {
    const { anchorMenu } = this.state;
    const open = Boolean(anchorMenu);

    return (
      <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.props.onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <React.Fragment>
                <IconButton
                  aria-owns={open ? "menu-appbar" : undefined}
                  aria-haspopup="true"
                  onClick={e => this.signOut(e)}
                  color="inherit"
                >
                  <Power />
                </IconButton>
              </React.Fragment>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  token: auth.userToken,
  name: `${auth.userData.firstName} ${auth.userData.lastName}`,
  firstName: auth.userData.firstName,
  lastName: auth.userData.lastName,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signOut: token => authActions.signOut(token)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(Header));
