import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dashboard as dashboardRoutes, auth as authRoutes } from "./index";

import AuthenticatedRoute from '../components/Routes/AuthenticatedRoute';
import UnauthenticatedRoute from '../components/Routes/UnauthenticatedRoute';

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";


const Routes = ({ childProps }) => (
  <Router>
    <Switch>
      {UnauthenticatedRoute(AuthLayout, authRoutes, childProps)}
      {AuthenticatedRoute(DashboardLayout, dashboardRoutes, childProps)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default Routes;
