import * as actionTypes from '../constants';

const initialState = {
  accounts: [],
  accountsFetchLoading: false,
  accountsFetchError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNTS:
      return {
        ...state,
        accountsFetchLoading: true
      };
    case actionTypes.FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accountsFetchLoading: false,
        accounts: action.data.accounts
      };
    case actionTypes.FETCH_ACCOUNTS_ERROR:
      return {
        ...state,
        accountsFetchLoading: false,
        accountsFetchError: action.errorMsg
      };
    case actionTypes.ACCOUNTS_CREATE:
      return {
        ...state,
        accountsCreateLoading: true
      };

    case actionTypes.ACCOUNTS_CREATE_SUCCESS:
      return {
        ...state,
        accountsCreateLoading: false,
        accountsCreateError: null,
        accounts: action.data.accounts,
      };

    case actionTypes.ACCOUNTS_CREATE_ERROR:
      return {
        ...state,
        accountsCreateLoading: false,
        accountsCreateError: action.errorMsg
      };

    case actionTypes.ACCOUNTS_UPDATE:
      return {
        ...state,
        accountsUpdateLoading: true
      };

    case actionTypes.ACCOUNTS_UPDATE_SUCCESS:
      return {
        ...state,
        accountsUpdateLoading: false,
        accountsUpdateError: null,
        accounts: action.data.accounts,
      };

    case actionTypes.ACCOUNTS_UPDATE_ERROR:
      return {
        ...state,
        accountsUpdateLoading: false,
        accountsUpdateError: action.errorMsg
      };

    case actionTypes.ACCOUNTS_DELETE:
      return {
        ...state,
        accountsDeleteLoading: true
      };

    case actionTypes.ACCOUNTS_DELETE_SUCCESS:
      return {
        ...state,
        accountsDeleteLoading: false,
        accountsDeleteError: null,
        accounts: action.data.accounts,
      };

    case actionTypes.ACCOUNTS_DELETE_ERROR:
      return {
        ...state,
        accountsDeleteLoading: false,
        accountsDeleteError: action.errorMsg
      };
    default:
      return state;
  }
}
