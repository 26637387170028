import React from "react";
import { toast } from 'react-toastify';
import { toastStatus, toastIcon } from './constants/State';

toast.configure();

const toastBody = ({type, info}) => (
  <div className='toast-body'>
    <div className='toast-icon d-flex'>
      <span><i className={`material-icons color-${toastStatus[type]}`}>{toastIcon[type]}</i></span>
      <div className={`toast-heading color-${toastStatus[type]}`}>{type}</div>
    </div>
    <div className='toast-content-wrapper'>
      <div className='toast-content'>{info}</div>
    </div>
  </div>
);

const handleInfo = (info) => {
  toast.info(toastBody({type: 'INFORMATION', info}), {containerId: 'A', position: toast.POSITION.TOP_RIGHT, className: 'toast-info', autoClose: 10000});
};

const handleWarning = (info) => {
  toast.warn(toastBody({type: 'WARNING', info}), {containerId: 'C', position: toast.POSITION.BOTTOM_RIGHT, className: 'toast-warning', autoClose: false, draggable: false, closeOnClick: false});
};

const handleError = (info) => {
  toast.error(toastBody({type: 'ERROR', info}), {containerId: 'B', position: toast.POSITION.BOTTOM_RIGHT, className: 'toast-error', autoClose: 10000});
};

const handlePermission = (info) => {
  toast.warn(toastBody({type: 'WARNING', info}), {containerId: 'D', position: toast.POSITION.TOP_RIGHT, className: 'toast-warning', autoClose: 10000});
};

export {
  handleError, handleWarning, handleInfo, handlePermission
}
