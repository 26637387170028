import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import DateFnsUtils from "@date-io/date-fns";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { browserName, deviceType } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import maTheme from "./theme";
import Routes from "./routes/Routes";
import "./index.css";
import Loader from "./components/Loader";
import authActions from "./redux/actions/auth";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      loadingError: null,
      modal: null
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('tozex_admin_token');
    if (!this.props.isAuthenticated && token) {
      this.props
        .fetchCurrentUser(token)
        .then(() => {
          this.setState({
            isLoaded: true
          });
        })
        .catch(e => {
          this.setState({
            isLoaded: true,
            loadingError: e
          });
        });
    } else if (!this.state.isLoaded) {
      this.setState({
        isLoaded: true
      });
    }
    let deviceInfo = {
      browser: browserName,
      device: deviceType ? deviceType : 'desktop'
    };
    this.props.getDeviceInfo(deviceInfo);
  }

  toggleModal = (modal = null) => {

    this.setState({ modal });
  };

  render(){
    const { isLoaded, modal } = this.state;

    const childProps = {
      isAuthenticated: this.props.isAuthenticated,
      modal,
      toggleModal: this.toggleModal
    };

    return isLoaded ? (
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[1]}>
            <ThemeProvider theme={maTheme[1]}>
              <Routes childProps={childProps}/>
            </ThemeProvider>
          </MuiThemeProvider>
          <ToastContainer />
        </MuiPickersUtilsProvider>

      </StylesProvider>
    ) : (
      <Loader/>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.userId !== null
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCurrentUser: token => authActions.fetchCurrentUser(token),
      getDeviceInfo: data => authActions.getDeviceInfo(data)
    },
    dispatch
  );

export default (
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(App)
  )
);
