export const USER_DEVICE_INFO = 'USER_DEVICE_INFO';
export const USER_DEVICE_INFO_SUCCESS = 'USER_DEVICE_INFO_SUCCESS';
export const USER_DEVICE_INFO_ERROR = 'USER_DEVICE_INFO_ERROR';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGIN_ERROR_RESET = 'USER_LOGIN_ERROR_RESET';

export const USER_CHANGE_AUTH_STORE_SUCCESS = 'USER_CHANGE_AUTH_STORE_SUCCESS';

export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_ERROR = 'FETCH_CURRENT_USER_ERROR';

export const USER_SIGNOUT = 'USER_SIGNOUT';
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS';
export const USER_SIGNOUT_ERROR = 'USER_SIGNOUT_ERROR';

export const USERS_FETCH = 'USERS_FETCH';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_ERROR = 'USERS_FETCH_ERROR';

export const USERS_FETCH_ALL = 'USERS_FETCH_ALL';
export const USERS_FETCH_ALL_SUCCESS = 'USERS_FETCH_ALL_SUCCESS';
export const USERS_FETCH_ALL_ERROR = 'USERS_FETCH_ALL_ERROR';

export const USERS_FETCH_TOTAL = 'USERS_FETCH_TOTAL';
export const USERS_FETCH_TOTAL_SUCCESS = 'USERS_FETCH_TOTAL_SUCCESS';
export const USERS_FETCH_TOTAL_ERROR = 'USERS_FETCH_TOTAL_ERROR';

export const USERS_FETCH_TOTAL_DATE = 'USERS_FETCH_TOTAL_DATE';
export const USERS_FETCH_TOTAL_DATE_SUCCESS = 'USERS_FETCH_TOTAL_DATE_SUCCESS';
export const USERS_FETCH_TOTAL_DATE_ERROR = 'USERS_FETCH_TOTAL_DATE_ERROR';

export const USERS_CREATE = 'USERS_CREATE';
export const USERS_CREATE_SUCCESS = 'USERS_CREATE_SUCCESS';
export const USERS_CREATE_ERROR = 'USERS_CREATE_ERROR';

export const USERS_UPDATE = 'USERS_UPDATE';
export const USERS_UPDATE_SUCCESS = 'USERS_UPDATE_SUCCESS';
export const USERS_UPDATE_ERROR = 'USERS_UPDATE_ERROR';

export const USERS_DELETE = 'USERS_DELETE';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_ERROR = 'USERS_DELETE_ERROR';

export const USERS_FETCH_SPECIAL = 'USERS_FETCH_SPECIAL';
export const USERS_FETCH_SPECIAL_SUCCESS = 'USERS_FETCH_SPECIAL_SUCCESS';
export const USERS_FETCH_SPECIAL_ERROR = 'USERS_FETCH_SPECIAL_ERROR';

export const USERS_UPDATE_PASSWORD = 'USERS_UPDATE_PASSWORD';
export const USERS_UPDATE_PASSWORD_SUCCESS = 'USERS_UPDATE_PASSWORD_SUCCESS';
export const USERS_UPDATE_PASSWORD_ERROR = 'USERS_UPDATE_PASSWORD_ERROR';

export const USERS_SEND_LINK = 'USERS_SEND_LINK';
export const USERS_SEND_LINK_SUCCESS = 'USERS_SEND_LINK_SUCCESS';
export const USERS_SEND_LINK_ERROR = 'USERS_SEND_LINK_ERROR';

export const USERS_CHANGE_STORE_SUCCESS = 'USERS_CHANGE_STORE_SUCCESS';

export const UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO';
export const UPDATE_ACCOUNT_INFO_SUCCESS = 'UPDATE_ACCOUNT_INFO_SUCCESS';
export const UPDATE_ACCOUNT_INFO_ERROR = 'UPDATE_ACCOUNT_INFO_ERROR';

export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';

export const DISABLE_TFA = 'DISABLE_TFA';
export const DISABLE_TFA_SUCCESS = 'DISABLE_TFA_SUCCESS';
export const DISABLE_TFA_ERROR = 'DISABLE_TFA_ERROR';

export const FETCH_2FA_QR = 'FETCH_2FA_QR';
export const FETCH_2FA_QR_SUCCESS = 'FETCH_2FA_QR_SUCCESS';
export const FETCH_2FA_QR_ERROR = 'FETCH_2FA_QR_ERROR';

export const USERS_DISABLE_2FA = 'USERS_DISABLE_2FA';
export const USERS_DISABLE_2FA_SUCCESS = 'USERS_DISABLE_2FA_SUCCESS';
export const USERS_DISABLE_2FA_ERROR = 'USERS_DISABLE_2FA_ERROR';

export const USERS_UPDATE_TOZ = 'USERS_UPDATE_TOZ';
export const USERS_UPDATE_TOZ_SUCCESS = 'USERS_UPDATE_TOZ_SUCCESS';
export const USERS_UPDATE_TOZ_ERROR = 'USERS_UPDATE_TOZ_ERROR';

export const USERS_RESET_TOZ = 'USERS_RESET_TOZ';
export const USERS_RESET_TOZ_SUCCESS = 'USERS_RESET_TOZ_SUCCESS';
export const USERS_RESET_TOZ_ERROR = 'USERS_RESET_TOZ_ERROR';

export const ENABLE_TFA = 'ENABLE_TFA';
export const ENABLE_TFA_SUCCESS = 'ENABLE_TFA_SUCCESS';
export const ENABLE_TFA_ERROR = 'ENABLE_TFA_ERROR';

export const VERIFY_TFA = 'VERIFY_TFA';
export const VERIFY_TFA_SUCCESS = 'VERIFY_TFA_SUCCESS';
export const VERIFY_TFA_ERROR = 'VERIFY_TFA_ERROR';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const USER_TFA_LOGIN = 'USER_TFA_LOGIN';
export const USER_TFA_LOGIN_SUCCESS = 'USER_TFA_LOGIN_SUCCESS';
export const USER_TFA_LOGIN_ERROR = 'USER_TFA_LOGIN_ERROR';

export const USER_TFA_DENY = 'USER_TFA_DENY';
export const USER_TFA_DENY_SUCCESS = 'USER_TFA_DENY_SUCCESS';
export const USER_TFA_DENY_ERROR = 'USER_TFA_DENY_ERROR';

export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_ERROR = 'USER_FORGOT_PASSWORD_ERROR';

export const FETCH_TRANS_USERS_SUCCESS = 'FETCH_TRANS_USERS_SUCCESS';
export const USER_TFA_ENABLED = 'USER_TFA_ENABLED';

export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_ERROR = 'FETCH_ACCOUNTS_ERROR';

export const ACCOUNTS_UPDATE = 'ACCOUNTS_UPDATE';
export const ACCOUNTS_UPDATE_SUCCESS = 'ACCOUNTS_UPDATE_SUCCESS';
export const ACCOUNTS_UPDATE_ERROR = 'ACCOUNTS_UPDATE_ERROR';

export const ACCOUNTS_DELETE = 'ACCOUNTS_DELETE';
export const ACCOUNTS_DELETE_SUCCESS = 'ACCOUNTS_DELETE_SUCCESS';
export const ACCOUNTS_DELETE_ERROR = 'ACCOUNTS_DELETE_ERROR';

export const ACCOUNTS_CREATE = 'ACCOUNTS_CREATE';
export const ACCOUNTS_CREATE_SUCCESS = 'ACCOUNTS_CREATE_SUCCESS';
export const ACCOUNTS_CREATE_ERROR = 'ACCOUNTS_CREATE_ERROR';

export const ACCOUNT_SET_PASSWORD = 'ACCOUNT_SET_PASSWORD';
export const ACCOUNT_SET_PASSWORD_SUCCESS = 'ACCOUNT_SET_PASSWORD_SUCCESS';
export const ACCOUNT_SET_PASSWORD_ERROR = 'ACCOUNT_SET_PASSWORD_ERROR';

export const USERS_FETCH_IP_DUPLICATION = 'USERS_FETCH_IP_DUPLICATION';
export const USERS_FETCH_IP_DUPLICATION_SUCCESS = 'USERS_FETCH_IP_DUPLICATION_SUCCESS';
export const USERS_FETCH_IP_DUPLICATION_ERROR = 'USERS_FETCH_IP_DUPLICATION_ERROR';

export const USERS_FETCH_IP_USERS = 'USERS_FETCH_IP_USERS';
export const USERS_FETCH_IP_USERS_SUCCESS = 'USERS_FETCH_IP_USERS_SUCCESS';
export const USERS_FETCH_IP_USERS_ERROR = 'USERS_FETCH_IP_USERS_ERROR';

export const FETCH_CONTRACTS = 'FETCH_CONTRACTS';
export const FETCH_CONTRACTS_SUCCESS = 'FETCH_CONTRACTS_SUCCESS';
export const FETCH_CONTRACTS_ERROR = 'FETCH_CONTRACTS_ERROR';

export const FETCH_BROS = 'FETCH_BROS';
export const FETCH_BROS_SUCCESS = 'FETCH_BROS_SUCCESS';
export const FETCH_BROS_ERROR = 'FETCH_BROS_ERROR';

export const CREATE_BRO_INVEST = 'CREATE_BRO_INVEST';
export const CREATE_BRO_INVEST_SUCCESS = 'CREATE_BRO_INVEST_SUCCESS';
export const CREATE_BRO_INVEST_ERROR = 'CREATE_BRO_INVEST_ERROR';

export const USERS_REMOVE_TOZ = 'USERS_REMOVE_TOZ';
export const USERS_REMOVE_TOZ_SUCCESS = 'USERS_REMOVE_TOZ_SUCCESS';
export const USERS_REMOVE_TOZ_ERROR = 'USERS_REMOVE_TOZ_ERROR';

export const FETCH_DAILY_SIGNUPS = 'FETCH_DAILY_SIGNUPS';
export const FETCH_DAILY_SIGNUPS_SUCCESS = 'FETCH_DAILY_SIGNUPS_SUCCESS';
export const FETCH_DAILY_SIGNUPS_ERROR = 'FETCH_DAILY_SIGNUPS_ERROR';
